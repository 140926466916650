import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FileService} from '../../../services/rest/file.service';
import {File} from '../../../dtos/file/File';
import {TableSortExtension} from '../../core/table/sort/TableSortExtension';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmService} from '../../../services/confirm.service';
import {MessageService} from '../../../services/message.service';
import {TablePaginationUtils} from '../../core/table/pagination/TablePaginationUtils';
import {StringUtils} from '../../../utils/StringUtils';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {FileFilter} from '../../../dtos/file/FileFilter';
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss', '../../core/table.scss']
})
export class FileTableComponent extends TableSortExtension implements OnInit, AfterViewInit {
  InputButtonIconType = InputButtonIconType;
  ArrayUtils = ArrayUtils;

  files: File[] = [];

  selectedName: string = null;

  loading = true;
  first = 0;
  paginationSize: number = null;
  totalFiles: number;
  lastFilter: FileFilter = this.createFilter();

  @ViewChild('tableParentDiv', {read: ElementRef, static: false}) tableParentDiv: ElementRef;
  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.FILES);

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fileService: FileService,
              private confirmService: ConfirmService,
              private messageService: MessageService) {
    super();
    this.setOnSortOrderChangedCallbackFunction(this.onSearch);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.processParams(params));
  }

  ngAfterViewInit(): void {
    TablePaginationUtils.calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchUsers(this.tableParentDiv, this.onSearch.bind(this));
  }

  onCreateFile() {
    console.log('Redirect to /files/create');
    this.router.navigate(['/files/create']);
  }

  onEditFile(file: File): void {
    console.log('Redirect to /files/' + file.id);
    this.router.navigate(['/files/' + file.id]);
  }

  onDeleteFile(file: File): void {
    this.confirmService.confirm(
      `File löschen`,
      `Wollen Sie die Gruppe ${file.name} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.fileService.deleteFileById(file.id).toPromise().then(() => {
          this.messageService.addSuccessMessage(`File ${file.name} wurde erfolgreich gelöscht!`);
          this.onSearch();
        });
      });
  }

  searchLazy(event: any) {
    if (!this.paginationSize) {
      return;
    }
    let firstRow = 0;
    if (event.first) {
      firstRow = event.first;
    }
    this.lastFilter.page = firstRow / this.paginationSize;
    this.lastFilter.size = this.paginationSize;
    this.searchFiles(this.lastFilter);
  }

  onSearch(paginationSize: number = this.paginationSize): void {
    this.paginationSize = paginationSize;
    if (paginationSize && paginationSize < this.files.length) {
      this.files = this.files.slice(0, paginationSize);
    }
    this.lastFilter = this.createFilter();
    this.first = 0;
    this.searchFiles(this.lastFilter);
  }

  private searchFiles(fileFilter: FileFilter): void {
    this.loading = true;
    this.fileService.getFiles(fileFilter)
      .pipe(delay(this.fileService.pageMinDelay))
      .subscribe((pagedFile: any) => {
        this.files = pagedFile.values;
        this.totalFiles = pagedFile.totalElements;
        this.loading = false;
      });
  }

  private createFilter(): FileFilter {
    return {
      page: 0,
      size: this.paginationSize,
      name: this.selectedName,
      sortList: this.sortList,
      // TODO: this is not implemented in the FE yet
      description: null,
      mimeType: null
    };
  }

  private processParams(params: Params): void {
    let searchAfterwards = false;
    const nameIsSet = StringUtils.isString(params.name);

    if (nameIsSet) {
      searchAfterwards = true;
      this.selectedName = params.name;
    }

    if (searchAfterwards) {
      this.onSearch();
    }
  }

}
