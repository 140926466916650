import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {httpInterceptorProviders} from './interceptors/interceptor.index';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {UserModule} from './components/users/user.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginModule} from './components/authentication/login/login.module';
import {RegistrationModule} from './components/authentication/registration/registration.module';
import {CoreModule} from './components/core/core.module';
import {PermissionModule} from './components/permissions/permission.module';
import {GroupModule} from './components/groups/group.module';
import {DashboardModule} from './components/dashboard/dashboard.module';
import {FileModule} from "./components/file/file.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FontAwesomeModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    UserModule,
    PermissionModule,
    GroupModule,
    FileModule,
    LoginModule,
    RegistrationModule,
    DashboardModule
  ],
  exports: [],
  providers: [
    httpInterceptorProviders
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
