import {PermissionTargetEnum} from './enums/PermissionTargetEnum';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export default {
  MENU: [
    {
      name: 'Allgemein',
      items: [
        {
          name: 'Dashboard',
          icon: ['fas', 'box-open'] as IconProp,
          link: 'dashboard',
          permissions: [PermissionTargetEnum.NO_PERMISSION]
        },
        {
          name: 'Dateien',
          icon: ['fas', 'file'] as IconProp,
          link: 'files',
          permissions: [PermissionTargetEnum.FILES]
        }
      ]
    },
    {
      name: 'Administration',
      items: [
        {
          name: 'Benutzer',
          icon: ['fas', 'user'] as IconProp,
          link: 'users',
          permissions: [PermissionTargetEnum.USERS]
        },
        {
          name: 'Gruppen',
          icon: ['fas', 'users'] as IconProp,
          link: 'groups',
          permissions: [PermissionTargetEnum.GROUPS]
        },
        {
          name: 'Berechtigungen',
          icon: ['fas', 'lock'] as IconProp,
          link: 'permissions',
          permissions: [PermissionTargetEnum.PERMISSIONS]
        }
      ]
    }
  ]
};
