<div class="row pt-5">

  <div class="col-md-6 mx-auto">

    <span class="anchor" id="formLogin"></span>

    <div class="card registration-card-body-border-radius">

      <div class="card-header">
        <h3 class="mb-0">Registrieren</h3>
      </div>

      <form [formGroup]="form" class="registration-card-body">

        <core-input-text
          [showClearButton]="false"
          formControlName="firstName"
          id="firstName"
          topLabel="Vorname">
        </core-input-text>

        <core-input-text
          [showClearButton]="false"
          formControlName="lastName"
          id="lastName"
          topLabel="Nachname">
        </core-input-text>

        <core-input-email
          [showClearButton]="false"
          formControlName="email"
          id="email"
          topLabel="E-Mail">
        </core-input-email>

        <core-input-password
          [enableBrowserSuggestion]="true"
          [showClearButton]="false"
          formControlName="password"
          id="password"
          topLabel="Passwort">
        </core-input-password>

        <core-input-password
          [enableBrowserSuggestion]="true"
          [showClearButton]="false"
          formControlName="confirmPassword"
          id="confirmPassword"
          topLabel="Passwort bestätigen">
        </core-input-password>

        <core-button
          (click)="onRegister()"
          [disabled]="form.invalid"
          [icon]="['fas', 'check']"
          [text]="'Registrieren'"
          [width]="'50%'"
          id="submit"
          style="text-align: center;">
        </core-button>

      </form>

      <p-progressBar *ngIf="loading" [style]="{'height': '10px'}" mode="indeterminate"></p-progressBar>

    </div>

  </div>

</div>
