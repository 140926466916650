import {Component} from '@angular/core';
import {InputButtonIconType} from "../core/button/button.utils";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/rest/authentication.service";
import {DashboardService} from "../../services/rest/dashboard.service";
import {Dashboard} from "../../dtos/dashboard/Dashboard";
import {DatePipe} from "@angular/common";
import {Analysis} from "../../dtos/dashboard/Analysis";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent {
  InputButtonIconType = InputButtonIconType;

  loading = true;
  dashboard: Dashboard;

  data: any;
  options: any;

  analysis: any;
  horizontalOptions: any;

  stocks: any;
  stockOptions: any;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private dashboardService: DashboardService,
              private datePipe: DatePipe) {
    this.getDashboard();
    this.options = {
      indexAxis: 'x',
      plugins: {
        legend: {
          display: true,
          position: 'bottom', // Positioning the legend on the right
        }
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          ticks: {
            callback: (value: string, index: any, ticks: any) => {
              return parseFloat(value).toFixed(1) + '%'; // Formats the number with one digit after the decimal point and appends a '%' symbol
            }
          }
        }
      },
      elements: {
        line: {
          borderWidth: 3 // Adjusts the line thickness (1 is thinner, increase for thicker lines)
        },
        point: {
          radius: 2 // Removes the data point markers
        }
      }
    };
    this.horizontalOptions = {
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false // This line hides the legend
        }
      }
    };
    this.stockOptions = {
      indexAxis: 'y',
      plugins: {
        legend: {
          display: true,
          position: 'right', // Positioning the legend on the right
          labels: {
            generateLabels: (chart: { data: any; }) => {
              const data = chart.data;
              return data.labels.map((label: any, index: string | number) => {
                const value = data.datasets[0].data[index];
                return {
                  text: `${value}% ${label}`, // Formats the label with the percentage
                  fillStyle: data.datasets[0].backgroundColor[index],
                };
              });
            }
          }
        }
      }
    };


  }

  getDashboard() {
    this.dashboardService.getDashboard().subscribe(
      resp => {
        this.dashboard = resp;
        this.mapChartData();
        this.loading = false;
      }
    )
  }


  mapChartData() {
    const colors = [
      '#FF5252',
      '#FF8F52',
      '#FFD152',
      '#489d3d',
      '#52A7FF',
      '#7A52FF',
      '#D252FF',
      '#FF52A7',
      '#52FFDE',
      '#FFD27A'
    ];

    this.stocks = {
      labels: this.dashboard.portfolio.allStocks.slice(0, 10).map(stock => stock.stock.name),
      datasets: [{
        data: this.dashboard.portfolio.allStocks.slice(0, 10).map(stock => stock.holdingPercentage),
        backgroundColor: colors, // This will repeat the colors if there are more stocks than colors
        hoverBackgroundColor: colors
      }]
    };

    // Create an array of objects with stock names and percentages
    let stocks = [
      {name: this.dashboard.analysis.stock1.stock.name, percentage: this.dashboard.analysis.stock1.percentage},
      {name: this.dashboard.analysis.stock2.stock.name, percentage: this.dashboard.analysis.stock2.percentage},
      {name: this.dashboard.analysis.stock3.stock.name, percentage: this.dashboard.analysis.stock3.percentage},
      {name: this.dashboard.analysis.stock4.stock.name, percentage: this.dashboard.analysis.stock4.percentage}
    ];

    // Sort the array in descending order by percentage
    stocks.sort((a, b) => b.percentage - a.percentage);

    // Define an array of colors for the bars
    const barColors = ['#42A5F5', '#FFA726', '#66BB6A', '#FF7043']; // Add more colors as needed

    // Map the sorted data to labels and dataset
    this.analysis = {
      labels: stocks.map(stock => stock.name),
      datasets: [
        {
          label: '',
          backgroundColor: stocks.map((_, index) => barColors[index % barColors.length]),
          data: stocks.map(stock => stock.percentage)
        }
      ]
    };
    this.data = {
      labels: this.dashboard.summary.map(s => this.datePipe.transform(s.date, 'dd.MM.yyyy')),
      datasets: [
        {
          label: 'Portfolio',
          data: this.dashboard.summary.map(s => s.stocksReturn),
          fill: false,
          borderColor: '#42A5F5'
        },
        {
          label: 'ACWI',
          data: this.dashboard.summary.map(s => s.acwiReturn),
          fill: false,
          borderColor: '#5F0F40A5'
        },
        {
          label: 'SP500',
          data: this.dashboard.summary.map(s => s.sp500Return),
          fill: false,
          borderColor: '#66BB6A'
        },
        {
          label: 'ATX',
          data: this.dashboard.summary.map(s => s.atxReturn),
          fill: false,
          borderColor: '#FF7043'
        },
        {
          label: 'Gold',
          data: this.dashboard.summary.map(s => s.goldReturn),
          fill: false,
          borderColor: '#FFA726'
        }
      ]
    };
  }

  onLogin() {
    this.router.navigate(['login']);
  }

  userLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  getWinningStock(a: Analysis) {
    let win = a.stock1;
    if (a.stock2.percentage > win.percentage)
      win = a.stock2;
    if (a.stock3.percentage > win.percentage)
      win = a.stock3;
    if (a.stock4.percentage > win.percentage)
      win = a.stock4;
    return win.stock;
  }

  isMobile(): boolean {
    // Regular expression to detect mobile devices by user agent string
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
  }

}
