import {NgModule} from '@angular/core';
import {CoreInputModule} from './input/core-input.module';
import {CoreMenuModule} from './menu/core-menu.module';
import {CoreButtonModule} from './button/core-button.module';
import {CoreDirectiveModule} from "./directive/core-directive.module";

@NgModule({
  declarations: [],
  imports: [CoreButtonModule, CoreInputModule, CoreMenuModule, CoreDirectiveModule],
  exports: [CoreButtonModule, CoreInputModule, CoreMenuModule, CoreDirectiveModule],
  providers: []
})
export class CoreModule {

}
