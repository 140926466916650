import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {User} from '../../../dtos/user/User';
import {UserFilter} from '../../../dtos/user/UserFilter';
import {PagedUser} from '../../../dtos/user/PagedUser';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ConfirmService} from '../../../services/confirm.service';
import {UserUpdate} from '../../../dtos/user/UserUpdate';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {TablePaginationUtils} from '../../core/table/pagination/TablePaginationUtils';
import {TableSortExtension} from '../../core/table/sort/TableSortExtension';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {StringUtils} from '../../../utils/StringUtils';
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss', '../../core/table.scss']
})
export class UserTableComponent extends TableSortExtension implements OnInit, AfterViewInit {
  InputButtonIconType = InputButtonIconType;
  ArrayUtils = ArrayUtils;

  users: User[] = [];
  currentUser: User = null;

  selectedEmail: string = null;
  selectedFirstName: string = null;
  selectedLastName: string = null;
  selectedLockedValue: boolean = null;

  loading = true;
  first = 0;
  paginationSize: number = null;
  totalUsers: number;
  lastFilter: UserFilter = this.createFilter();

  // html config
  hideExpandedSearch = true;

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.USERS);

  @ViewChild('tableParentDiv', {read: ElementRef, static: false}) tableParentDiv: ElementRef;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private messageService: MessageService,
              private confirmService: ConfirmService) {
    super();
    this.setOnSortOrderChangedCallbackFunction(this.onSearch);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.processParams(params));
    this.userService.getCurrentUser().subscribe((currentUser: User) => this.currentUser = currentUser);
  }

  ngAfterViewInit(): void {
    this.calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchUsers()
  }

  calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchUsers() {
    TablePaginationUtils.calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchUsers(this.tableParentDiv, this.onSearch.bind(this));
  }

  onSearch(paginationSize: number = this.paginationSize): void {
    this.paginationSize = paginationSize;
    if (paginationSize && paginationSize < this.users.length) {
      this.users = this.users.slice(0, paginationSize);
    }
    this.lastFilter = this.createFilter();
    this.first = 0;
    this.searchUsers(this.lastFilter);
  }

  onCreateUser() {
    console.log('Redirect to /users/create');
    this.router.navigate(['/users/create']);
  }

  onEditUserClick(user: User): void {
    console.log('Redirect to /users/' + user.id);
    this.router.navigate(['/users/' + user.id]);
  }

  onLockUserClick(user: User): void {
    const isLocked = user.locked ? 'entsperren' : 'sperren';
    this.confirmService.confirm(
      `Benutzer ${isLocked}`,
      `Wollen Sie den Benutzer ${user.firstName} ${user.lastName} wirklich ${isLocked}?`,
      () => {
        const updatedUser: UserUpdate = {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          locked: !user.locked,
          menuExpanded: user.menuExpanded,
          password: null,
          permissionIds: null,
          groupIds: null
        };
        this.userService.update(updatedUser).toPromise().then(resp => {
          const wasLocked = resp.locked ? 'gesperrt' : 'entsperrt';
          this.messageService.addSuccessMessage(`Benutzer ${user.firstName} ${user.lastName} wurde erfolgreich ${wasLocked}!`);
          user.locked = resp.locked
          console.log(JSON.stringify(user));
          console.log(JSON.stringify(resp));
        });
      });
  }

  onDeleteUserClick(user: User): void {
    this.confirmService.confirm(
      `Benutzer löschen`,
      `Wollen Sie den Benutzer ${user.firstName} ${user.lastName} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.userService.deleteUserById(user.id).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Benutzer ${user.firstName} ${user.lastName} wurde erfolgreich gelöscht!`);
          this.onSearch();
        });
      });
  }

  searchLazy(event: any) {
    if (!this.paginationSize) {
      return;
    }
    let firstRow = 0;
    if (event.first) {
      firstRow = event.first;
    }
    this.lastFilter.page = firstRow / this.paginationSize;
    this.lastFilter.size = this.paginationSize;
    this.searchUsers(this.lastFilter);
  }

  booleanGerman(value: boolean): string {
    if (value === undefined || value === null) {
      return 'undefiniert';
    }
    return value ? 'ja' : 'nein';
  }

  private searchUsers(userFilter: UserFilter): void {
    this.loading = true;
    this.userService.getUsers(userFilter)
      .pipe(delay(this.userService.pageMinDelay))
      .subscribe((pagedUsers: PagedUser) => {
      this.users = pagedUsers.values;
      this.totalUsers = pagedUsers.totalElements;
      this.loading = false;
    });
  }

  private createFilter(): UserFilter {
    return {
      page: 0,
      size: this.paginationSize,
      email: this.selectedEmail,
      firstName: this.selectedFirstName,
      lastName: this.selectedLastName,
      locked: this.selectedLockedValue,
      sortList: this.sortList
    };
  }

  private processParams(params: Params): void {
    let searchAfterwards = false;
    const emailIsSet = StringUtils.isString(params.email);

    if (emailIsSet) {
      searchAfterwards = true;
      this.selectedEmail = params.email;
    }

    if (searchAfterwards) {
      this.onSearch();
    }
  }

}
