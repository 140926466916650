import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import {Globals} from '../../global/globals';
import {AuthenticationRequest} from '../../dtos/authentication/AuthenticationRequest';
import {SimplePermission} from '../../dtos/permission/SimplePermission';
import {PermissionActionEnum} from '../../enums/PermissionActionEnum';
import {PermissionTargetEnum} from '../../enums/PermissionTargetEnum';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authBaseUri: string = this.globals.backendUri + '/authentication';

  constructor(private httpClient: HttpClient, private globals: Globals, private router: Router) {
  }

  /**
   * Login in the user. If it was successful, a valid JWT token will be stored
   *
   * @param authRequest User data
   */
  loginUser(authRequest: AuthenticationRequest): Observable<string> {
    console.log('Login attempt for user ' + authRequest.email);
    return this.httpClient.post(this.authBaseUri, authRequest, {responseType: 'text'})
      .pipe(tap((authResponse: string) => {
        console.log('Login successful for user ' + authRequest.email);
        this.setToken(authResponse);
      }));
  }

  /**
   * check if a user is logged in
   * return true if a token is present and the token is not expired
   */
  isLoggedIn(): boolean {
    return this.getToken() && this.getTokenExpirationDate(this.getToken()).valueOf() > new Date().valueOf();
  }

  /**
   * get the token from the local storage
   */
  getToken() {
    return JSON.parse(JSON.stringify(localStorage.getItem('token')));
  }

  /**
   * get the userId from the token
   */
  getUserIdFromToken(): number {
    const decoded: any = jwt_decode(this.getToken());
    return decoded.userId;
  }

  /**
   * get the username from the token
   */
  getUserNameFromToken(): string {
    const decoded: any = jwt_decode(this.getToken());
    return decoded.sub;
  }

  /**
   * get all permissions from the token
   */
  getPermissionsFromToken(): SimplePermission[] {
    if (this.getToken() != null) {
      const decoded: any = jwt_decode(this.getToken());
      const permissionStrings: string = decoded.rol.split(',');
      const permissions: SimplePermission[] = [];
      for (const p of permissionStrings) {
        const action = p.split(':')[0];
        const target = p.split(':')[1];
        permissions.push(
          new SimplePermission(
            PermissionActionEnum.getEnumByValue(action),
            PermissionTargetEnum.getEnumByValue(target)));
      }
      return permissions;
    }
    return [];
  }

  /**
   * store the token into the local storage
   *
   * @param token
   */
  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  /**
   * logout user
   * clear the local storage and redirect the user to the login page
   */
  logoutUser() {
    console.log('Logout');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  private getTokenExpirationDate(token: string): Date | null {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

}
