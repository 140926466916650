import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MessageService} from '../services/message.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((httpError: HttpErrorResponse) => {

          // get server-error message
          let message = null;
          if (httpError.error &&
            httpError.error.length > 0) {
            message = httpError.error;
          }
          // show server message if not empty
          if (message && message.length > 0) {
            this.messageService.addErrorMessage(message);
          } else {
            if (httpError.status >= 400 && httpError.status < 500) {
              this.messageService.addErrorMessage('Unknown Client Error');
            } else if (httpError.status >= 500 && httpError.status < 600) {
              this.messageService.addErrorMessage('Unknown Server Error');
            } else if (httpError.status >= 900) {
              this.messageService.addErrorMessage('Unknown Server Error');
            } else {
              this.messageService.addErrorMessage('Unknown Error');
            }
          }
          return throwError(httpError);
        }));
  }

}
