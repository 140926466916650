import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import {SharedModule} from "../../shared/shared.module";
import {ChartModule} from "primeng/chart";
import {DatePipe} from "@angular/common";

@NgModule({
  declarations: [DashboardComponent],
    imports: [
        SharedModule,
        ChartModule
    ],
  exports: [DashboardComponent],
  providers: [DatePipe]
})
export class DashboardModule {

}
