<div class="core-edit-max-width">

  <div class="flex-row-space-between">

    <h1>Benutzer bearbeiten</h1>

    <div>

      <core-button-slight
        (click)="onDeleteUser(user)"
        *ngIf="form && currentUser && currentUser.id !== user?.id"
        [disabled]="form.disabled"
        [type]="InputButtonIconType.DELETE">
      </core-button-slight>
    </div>

  </div>

  <form *ngIf="user && form" [formGroup]="form">

    <core-input-text
      formControlName="firstName"
      id="firstName"
      label="Vorname">
    </core-input-text>

    <core-input-text
      formControlName="lastName"
      id="lastName"
      label="Nachname">
    </core-input-text>

    <core-input-email
      formControlName="email"
      id="email"
      label="E-Mail">
    </core-input-email>

    <core-input-dropdown
      [binary]="true"
      formControlName="locked"
      id="locked"
      label="Gesperrt">
    </core-input-dropdown>

    <core-input-multiselect
      [options]="allGroups"
      formControlName="groups"
      id="groups"
      label="Gruppen">
    </core-input-multiselect>

    <core-input-multiselect
      [options]="allPermissions"
      formControlName="permissions"
      id="permissions"
      label="Berechtigungen">
    </core-input-multiselect>

    <div class="flex-row-space-between">
      <div>
        <core-button-slight
          (click)="onResetPassword(user)"
          *ngIf="form && currentUser && (userCanChangePassword || currentUser.id === user?.id)"
          [disabled]="form.disabled"
          [text]="'Password zurücksetzten'"
          [type]="InputButtonIconType.EDIT"
          style="margin-right: 10px">
        </core-button-slight>

        <core-button-slight
          (click)="onChangePassword(user)"
          *ngIf="form && currentUser && (userCanChangePassword || currentUser.id === user?.id)"
          [disabled]="form.disabled"
          [text]="'Password ändern'"
          [type]="InputButtonIconType.EDIT"
          style="margin-right: 10px">
        </core-button-slight>

      </div>

      <core-button-submit
        (click)="submitChanges()"
        [disabled]="form.invalid || form.disabled"
        id="button_submit">
      </core-button-submit>
    </div>

  </form>

</div>
