<div class="h-100" style="display: flex; flex-direction: column">

  <div class="flex-row-space-between">

    <h1>Gruppen</h1>

    <core-button-icon-big
      id="button-create-group"
      (click)="onCreateGroup()"
      [type]="InputButtonIconType.ADD">
    </core-button-icon-big>

  </div>

  <div class="form-row">

    <core-input-email
      (onKeyUpEnter)="onSearch()"
      [(ngModel)]="selectedName"
      class="col-10"
      id="name"
      placeholder="Name">
    </core-input-email>

    <core-button
      (click)="onSearch()"
      [icon]="['fas', 'search']"
      [width]="'100%'"
      class="col-2"
      id="search"
      text="Suchen">
    </core-button>

  </div>

  <!-- Results -->
  <div #tableParentDiv class="h-100">
    <p-table
      (onLazyLoad)="searchLazy($event)"
      [(first)]="first"
      [autoLayout]="true"
      [lazy]="true"
      [loading]="loading"
      [paginator]="totalGroups > paginationSize"
      [rows]="paginationSize"
      [showCurrentPageReport]="true"
      [totalRecords]="totalGroups"
      [value]="groups"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Gruppen"
      dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th
            (click)="onSortOrderChanged('name')">
            <span>
              <span>Name</span>
              <span *ngIf="getSortIndexByFieldName('name')">
                {{getSortIndexByFieldName('name')}}
                <fa-icon [icon]="getSortIconByFieldName('name')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('description')">
            <span>
              <span>Beschreibung</span>
              <span *ngIf="getSortIndexByFieldName('description')">
                {{getSortIndexByFieldName('description')}}
                <fa-icon [icon]="getSortIconByFieldName('description')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            [ngClass]="userCanEdit ? 'group-table-column-action-buttons-width' : 'group-table-column-action-buttons-width-without-edit-access'"></th>
        </tr>
      </ng-template>
      <ng-template let-group pTemplate="body">
        <tr *ngIf="!loading">
          <td style="width: 30%">{{group.name}}</td>
          <td style="width: 70%">{{group.description}}</td>
          <td [ngClass]="userCanEdit ? 'group-table-column-action-buttons-width' : 'group-table-column-action-buttons-width-without-edit-access'"
              class="group-table-column-action-buttons">
            <core-button-icon
              (click)="onEditGroup(group)"
              [type]="(userCanEdit && group.name !== ADMIN_GROUP_NAME) ? InputButtonIconType.EDIT : InputButtonIconType.READ">
            </core-button-icon>
            <core-button-icon
              (click)="onDeleteGroup(group)"
              *ngIf="userCanEdit && group.name !== ADMIN_GROUP_NAME"
              [type]="InputButtonIconType.DELETE">
            </core-button-icon>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <ng-container *ngFor="let p of ArrayUtils.of(paginationSize)">
          <tr style="height: 40px">
            <td style="width: 25%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 65%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 10%">
              <p-skeleton></p-skeleton>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>
</div>
