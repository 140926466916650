import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './services/rest/authentication.service';
import {PrimeNGConfig} from 'primeng/api';
import {AppInfoService} from './services/rest/app-info.service';
import {AppInfo} from './dtos/general/AppInfo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Frontend';

  appInfo: AppInfo;

  constructor(private authenticationService: AuthenticationService,
              private appInfoService: AppInfoService,
              private primengConfig: PrimeNGConfig) {
    this.getAppInfo();
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  userLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  getUserNameFromToken(): string {
    return this.authenticationService.getUserNameFromToken();
  }

  getAppInfo() {
    this.appInfoService.getAppInfo().subscribe((appInfo: AppInfo) => {
      this.appInfo = appInfo;
    });
  }

}
