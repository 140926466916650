import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {PermissionService} from '../../../services/rest/permission.service';
import {Permission} from '../../../dtos/permission/Permission';
import {InputButtonIconType} from '../../core/button/button.utils';
import {Router} from '@angular/router';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {TableSortExtension} from '../../core/table/sort/TableSortExtension';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-permission-table',
  templateUrl: 'permission-table.component.html',
  styleUrls: ['permission-table.component.scss', '../../core/table.scss']
})
export class PermissionTableComponent extends TableSortExtension implements AfterViewInit {
  InputButtonIconType = InputButtonIconType;
  ArrayUtils = ArrayUtils;

  permissions: Permission[];

  loading = true;
  scrollHeight: number = 500;
  skeletonSize: number = 15;

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.PERMISSIONS);

  @ViewChild('tableParentDiv', {read: ElementRef, static: false}) tableParentDiv: ElementRef;

  constructor(private router: Router,
              private permissionService: PermissionService) {
    super();
    this.setOnSortOrderChangedCallbackFunction(this.sortListInFrontend);
    this.getPermissions();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const tableHeight = this.tableParentDiv.nativeElement.clientHeight;
      console.log('Table height: ' + tableHeight + 'px');
      this.scrollHeight = tableHeight;
    }, 10);
  }

  onEditPermissionClick(permission: Permission) {
    console.log('Redirect to /permissions/' + permission.id);
    this.router.navigate(['/permissions/' + permission.id]);
  }

  private sortListInFrontend() {
    this.sortInFrontend(this.permissions);
  }

  private getPermissions() {
    this.loading = true;
    this.permissionService.getAllPermissions()
      .pipe(delay(this.permissionService.pageMinDelay))
      .subscribe(permissions => {
      this.permissions = permissions;
      this.loading = false;
    });
  }

}
