<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div class="core-input-label-container">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''"
       class="p-inputgroup core-input-container core_input_flex_between">
    <p-dropdown
      (keyup.enter)="onKeyUpEnter.emit(value)"
      (ngModelChange)="valueChanged($event)"
      [autoDisplayFirst]="false"
      [disabled]="disabled"
      [id]="id"
      [ngClass]="control.touched && control.errors ? 'core_input_invalid' : ''"
      [ngModel]="value"
      [options]="binary ? binaryOptions : _options"
      [placeholder]="placeholder"
      [style]="{width: '100%',
      'border-color': control.touched && control.errors ? 'inherit' : '',
      'border-top-right-radius': 0,
      'border-bottom-right-radius': 0,
      height: '35px'}"
      optionLabel="name"
      optionValue="id">
    </p-dropdown>
    <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
    <button
      (click)="onClear()"
      *ngIf="showClearButton"
      [disabled]="disabled"
      class="p-button-secondary core-input-button-clear p-inputgroup-addon"
      icon="pi pi-times"
      pButton
      tabindex="-1">
    </button>
  </div>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
</div>
