import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {PermissionService} from '../../../services/rest/permission.service';
import {GroupService} from '../../../services/rest/group.service';
import {SelectItem} from '../../../dtos/SelectItem';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {Group} from '../../../dtos/group/Group';
import {GroupUpdate} from '../../../dtos/group/GroupUpdate';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ConfirmService} from '../../../services/confirm.service';
import {NumberUtils} from '../../../utils/NumberUtils';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {
  InputButtonIconType = InputButtonIconType;

  REDIRECT_ON_ERROR = '/groups';
  REDIRECT_ON_SUCCESS = '/groups';

  ADMIN_GROUP_NAME = 'Admin';

  group: Group;

  form: FormGroup;

  allPermissions: SelectItem[] = [];

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.GROUPS);

  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private permissionService: PermissionService,
              private groupService: GroupService,
              private messageService: MessageService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private confirmService: ConfirmService,
              private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params.id;
      if (!NumberUtils.isNumber(id)) {
        this.messageService.addErrorMessage("Kein gültiger Parameter in der URL!");
        console.log('Given parameter is not valid: ' + id);
        console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
        this.router.navigate([this.REDIRECT_ON_ERROR]);
        return;
      }
      this.getGroup(id);
    });
    this.getPermissions();
  }

  submitChanges() {
    if (this.form.valid) {
      const group: GroupUpdate = {
        id: this.form.controls.id.value,
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        permissionIds: this.form.controls.permissions.value
      };
      this.groupService.updateGroup(group).subscribe(() => {
        this.messageService.addSuccessMessage('Gruppe erfolgreich bearbeitet!');
      });
    }
  }

  onDeleteGroup(group: Group): void {
    this.confirmService.confirm(
      `Benutzer löschen`,
      `Wollen Sie die Gruppe ${group.name} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.groupService.deleteGroupById(group.id).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Gruppe ${group.name} wurde erfolgreich gelöscht!`);
          console.log('Redirect to ' + this.REDIRECT_ON_SUCCESS);
          this.router.navigate([this.REDIRECT_ON_SUCCESS]);
        });
      });
  }

  private getGroup(id: number) {
    this.groupService.getGroupById(id).subscribe((group: Group) => {
        this.group = group;
        this.createForm(group);
      }, () => {
        console.log('No group found with id: ' + id);
        console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
        this.router.navigate([this.REDIRECT_ON_ERROR]);
      });
  }

  private createForm(group: Group) {
    this.form = this.formBuilder.group({
      id: [group.id],
      name: [group.name, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: [group.description, [Validators.maxLength(255)]],
      permissions: [group.permissions.map(p => p.id)]
    });
    // nobody can edit the admin group
    if (group.name === this.ADMIN_GROUP_NAME) {
      this.userCanEdit = false;
      this.messageService.addInfoMessage(this.ADMIN_GROUP_NAME + '-Gruppe kann nicht bearbeitet werden!')
    }
    if (!this.userCanEdit) {
      this.form.disable();
    }
  }

  private getPermissions() {
    this.permissionService.getAllPermissionsAsSelectItems().subscribe(permissions => this.allPermissions = permissions);
  }

}
