import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {delay, tap} from 'rxjs/operators';
import {Globals} from '../../global/globals';
import {Router} from '@angular/router';
import {Dashboard} from "../../dtos/dashboard/Dashboard";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUri: string = this.globals.backendUri + '/dashboard';

  constructor(private httpClient: HttpClient, private globals: Globals, private router: Router) {
  }

  /**
   * get the current dashboard overview
   */
  getDashboard(): Observable<Dashboard> {
    console.log('Get the current dashboard overview');
    return this.httpClient.get<Dashboard>(this.baseUri)
      .pipe(delay(1500))
      .pipe(tap(resp => {
        if (resp.analysis?.endDate)
          resp.analysis.endDate = new Date(resp.analysis.endDate);
        if (resp.analysis?.startDate)
          resp.analysis.startDate = new Date(resp.analysis.startDate);
        if (resp.analysis?.targetDate)
          resp.analysis.targetDate = new Date(resp.analysis.targetDate);
        for (let analysis of resp.previousAnalysis) {
          if (analysis.endDate)
            analysis.endDate = new Date(analysis.endDate);
          if (analysis.startDate)
            analysis.startDate = new Date(analysis.startDate);
          if (analysis.targetDate)
            analysis.targetDate = new Date(analysis.targetDate);
        }
        if (resp.portfolio.date)
          resp.portfolio.date = new Date(resp.portfolio.date);
        for (let portfolio of resp.summary) {
          if (portfolio.date)
            portfolio.date = new Date(portfolio.date);
        }
        resp.previousAnalysis.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());
        resp.summary.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        return resp;
      }));
  }

}
