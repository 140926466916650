<button
  (click)="onClick.emit($event)"
  [classList]="getClassForType(type)"
  [disabled]="disabled"
  [id]="id"
  [ngStyle]="{width: width}"
  pButton
  pRipple
  type="button">
  {{getTextForType(type)}}
</button>
