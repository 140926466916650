import {Injectable} from '@angular/core';
import {AppInfo} from '../../dtos/general/AppInfo';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor() {
  }

  // for now this is some static data
  // this values should be set in the build process
  // maybe add a service in the backend if required?
  /**
   * get information about the app
   */
  getAppInfo(): Observable<AppInfo> {
    const appInfo: AppInfo = {
      build: 'f8b5m3t6h1',
      buildOn: '11.01.2024',
      databaseName: 'mariadb@mgum',
      instance: 'prod',
      version: '1.0.0'
    };
    return of(appInfo);
  }

}
