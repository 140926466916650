<p-toast
  [style]="{width: '330px', 'z-index': 10000}"
  position="top-right">
</p-toast>

<div style="width: 100%; height: 100%; display: flex;">

  <core-side-menu
    *ngIf="userLoggedIn()"
    [appInfo]="appInfo"
    [username]="getUserNameFromToken()">
  </core-side-menu>

  <div class="w-100 h-100" style="padding: 20px;">
    <router-outlet>
    </router-outlet>
  </div>

</div>

<p-confirmDialog
  #confirmDialog
  [closable]=false
  [closeOnEscape]=false
  key="confirmDialog">
</p-confirmDialog>
