import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {Observable} from 'rxjs';
import {SelectItem} from '../../dtos/SelectItem';
import {Permission} from '../../dtos/permission/Permission';
import {PermissionUpdate} from '../../dtos/permission/PermissionUpdate';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  pageMinDelay: number = this.globals.pageMinDelay;

  private permissionBaseUrl: string = this.globals.backendUri + '/permissions';
  private selectUrl: string = this.permissionBaseUrl + '/select';

  constructor(private http: HttpClient, private globals: Globals) {
  }

  /**
   * get all permissions as select-items
   */
  getAllPermissionsAsSelectItems(): Observable<SelectItem[]> {
    console.log('Get all permissions as select items');
    return this.http.get<SelectItem[]>(this.selectUrl);
  }

  /**
   * get all permissions
   */
  getAllPermissions(): Observable<Permission[]> {
    console.log('Get all permissions');
    return this.http.get<Permission[]>(this.permissionBaseUrl);
  }

  /**
   * get permission by id
   *
   * @param permissionId
   */
  getPermissionById(permissionId: number): Observable<Permission> {
    console.log('Get permission with id ' + permissionId);
    return this.http.get<Permission>(this.permissionBaseUrl + '/' + permissionId);
  }

  /**
   * update name and description of a permission
   *
   * @param permission holds the id, name and description
   */
  updatePermission(permission: PermissionUpdate): Observable<Permission> {
    console.log('Update permission with id ' + permission.id);
    return this.http.put<Permission>(this.permissionBaseUrl, permission);
  }

}
