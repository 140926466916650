<div class="header">

  <div class="header-last-synced" style="width: 20%">
    <ng-container *ngIf="dashboard?.portfolio?.date">
      last synced on {{dashboard.portfolio.date | date: 'dd.MM.yyyy \'at\' HH:mm:ss'}}
    </ng-container>
  </div>

  <h1>Frederick's Financial Adventures</h1>

  <div style="width: 20%; display: flex; justify-content: end">
    <button
      id="login"
      *ngIf="!userLoggedIn()"
      (click)="onLogin()">
      Login
    </button>
  </div>

</div>

<div *ngIf="loading" class="loading-indicator">
  <p-progressSpinner></p-progressSpinner>
</div>

<div class="dashboard-container" *ngIf="!loading">

  <!-- Diagram Section -->
  <div class="chart-container card">
    <h2>Portfolio performance comparison (%)</h2>
    <p-chart type="line" [data]="data" [options]="options" height="32vh"></p-chart>
  </div>

  <!-- Stocks List Section -->
  <div>
    <div class="card">
      <h2>Top 10 holdings</h2>
      <p-chart type="doughnut" [data]="stocks" [options]="stockOptions" height="30vh"></p-chart>
    </div>
  </div>

  <!-- Analysis Section -->
  <div>
    <div class="card">
      <div class="current-analysis">
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <h2>Ongoing analysis</h2>
          <i class="fa-solid fa-circle-info icon" [pTooltip]="dashboard.analysis?.aiDescription"></i>
        </div>
        <p style="margin-bottom: 0">Begun on {{ dashboard.analysis.startDate | date: 'dd.MM.yyyy' }} with
          target {{ dashboard.analysis.targetDate | date: 'dd.MM.yyyy HH:mm' }}</p>
        <p-chart type="bar" [data]="analysis" [options]="horizontalOptions" height="15vh"></p-chart>
      </div>
      <div *ngIf="isMobile()" class="ai-description">
        <b>Unraveling the Mysteries of Frederick's Mind</b>
        <div>{{dashboard.analysis?.aiDescription}}</div>
      </div>
      <div class="previous-analyses">
        <b>Finished analysis</b>
        <div class="previous-analyses-item-container">
          <div *ngFor="let a of dashboard.previousAnalysis" class="previous-analyses-item">
            <div>
              <img class="previous-analyses-item-img"
                   [src]="getWinningStock(a).logoUrl"
                   [alt]="getWinningStock(a).logoUrl">
              {{ getWinningStock(a).name }}
            </div>
            <div style="display: flex; flex-direction: row; gap: 10px">
              <div> {{a.endDate | date: ' dd.MM.yyyy'}} </div>
              <i class="fa-solid fa-circle-info icon" [pTooltip]="a.aiDescriptionFinal"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Image Section -->
  <div>
    <div class="card">
      <h2> Frederick at work</h2>
      <img
        *ngIf="dashboard.image"
        class="img"
        [src]="dashboard.image"
        alt="Stock Image">
    </div>
  </div>

</div>
