import {NgModule} from '@angular/core';
import {SideMenuComponent} from './side-menu.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TooltipModule} from 'primeng/tooltip';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [SideMenuComponent],
  imports: [RouterModule, CommonModule, OverlayPanelModule, TooltipModule, FontAwesomeModule
  ],
  exports: [SideMenuComponent],
  providers: []
})
export class SideMenuModule {
  constructor(library: FaIconLibrary) {
    // Add icon-collections to library
    library.addIconPacks(fas);
    library.addIconPacks(far);

  }

}
