import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {User} from '../../../dtos/user/User';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {UserUpdate} from '../../../dtos/user/UserUpdate';
import {UserDetail} from '../../../dtos/user/UserDetail';
import {PermissionService} from '../../../services/rest/permission.service';
import {GroupService} from '../../../services/rest/group.service';
import {SelectItem} from '../../../dtos/SelectItem';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ConfirmService} from '../../../services/confirm.service';
import {NumberUtils} from '../../../utils/NumberUtils';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  InputButtonIconType = InputButtonIconType;

  REDIRECT_ON_SUCCESS = '/users';
  REDIRECT_ON_ERROR = '/users';


  user: UserDetail;
  currentUser: User;
  form: FormGroup;

  allPermissions: SelectItem[] = [];
  allGroups: SelectItem[] = [];

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.PERMISSIONS);
  userCanChangePassword: boolean = AuthUtils.canEdit(PermissionTargetEnum.USERS_PASSWORD);

  constructor(private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private route: ActivatedRoute,
              private userService: UserService,
              private permissionService: PermissionService,
              private groupService: GroupService,
              private messageService: MessageService,
              private router: Router,
              private confirmService: ConfirmService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const id = params.id;
      if (!NumberUtils.isNumber(id)) {
        this.messageService.addErrorMessage("Kein gültiger Parameter in der URL!");
        console.log('Given parameter is not valid: ' + id);
        console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
        this.router.navigate([this.REDIRECT_ON_ERROR]);
        return;
      }
      this.getUser(id);
    });
    this.getCurrentUser();
    this.getPermissions();
    this.getGroups();
  }

  submitChanges() {
    if (this.form.valid) {
      const user: UserUpdate = {
        id: this.form.controls.id.value,
        email: this.form.controls.email.value,
        firstName: this.form.controls.firstName.value,
        lastName: this.form.controls.lastName.value,
        menuExpanded: this.form.controls.menuExpanded.value,
        locked: this.form.controls.locked.value,
        password: null,
        permissionIds: this.form.controls.permissions.value,
        groupIds: this.form.controls.groups.value
      };
      // ask before submitting changes on the own user
      if (this.currentUser.id === this.user.id) {
        this.confirmService.confirm(
          `Benutzer bearbeiten`,
          `Wollen Sie Ihren Benutzer wirklich bearbeiten? <br>` +
          `Falls Sie Gruppen oder Berechtigungen entfernen verlieren Sie den Zugriff auf diese Resource!`,
          () => {
            this.userService.update(user).subscribe(() => {
              this.messageService.addSuccessMessage('Benutzer erfolgreich bearbeitet!');
            });
          }
        );
      } else {
        this.userService.update(user).subscribe(() => {
          this.messageService.addSuccessMessage('Benutzer erfolgreich bearbeitet!');
        });
      }
    }
  }

  onDeleteUser(user: User): void {
    this.confirmService.confirm(
      `Benutzer löschen`,
      `Wollen Sie den Benutzer ${user.firstName} ${user.lastName} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.userService.deleteUserById(user.id).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Benutzer ${user.firstName} ${user.lastName} wurde erfolgreich gelöscht!`);
          console.log('Redirect to ' + this.REDIRECT_ON_SUCCESS);
          this.router.navigate([this.REDIRECT_ON_SUCCESS]);
        });
      });
  }

  onChangePassword(user: User) {
    this.router.navigate(['users/change-password/' + user.id]);
  }

  onResetPassword(user: User): void {
    this.confirmService.confirm(`Passwort zurücksetzen`,
      `Wollen Sie das Passwort für Benutzer ${user.firstName} ${user.lastName} wirklich zurücksetzen? <br>` +
      `Es wird eine E-Mail mit einem neuen Passwort an ${user.email} gesendet! <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.userService.resetUserPassword(user.email).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Passwort von ${user.firstName} ${user.lastName} wurde erfolgreich zurückgesetzt!`);
        }).catch(err => {
          console.log(err);
        });
      });
  }

  private getPermissions() {
    this.permissionService.getAllPermissionsAsSelectItems().subscribe(permissions => this.allPermissions = permissions);
  }

  private getGroups() {
    this.groupService.getAllGroupsAsSelectItems().subscribe(groups => this.allGroups = groups);
  }

  private getCurrentUser() {
    this.userService.getCurrentUser().subscribe(currentUser => this.currentUser = currentUser);
  }

  private getUser(id: number) {
    this.userService.getUserById(id).subscribe((user: UserDetail) => {
      this.user = user;
      this.createForm(user);
    }, () => {
      console.log('No user found with id: ' + id);
      console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
      this.router.navigate([this.REDIRECT_ON_ERROR]);
      return;
    });
  }

  private createForm(user: UserDetail) {
    this.form = this.formBuilder.group({
      id: [user.id],
      firstName: [user.firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      lastName: [user.lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      email: [user.email, [Validators.required, Validators.email, Validators.maxLength(255)]],
      menuExpanded: [user.menuExpanded, [Validators.required]],
      locked: [user.locked, [Validators.required]],
      permissions: [user.permissions.map(p => p.id)],
      groups: [user.groups.map(g => g.id)]
    });
    if (!this.userCanEdit) {
      this.form.disable();
    }
  }

}
