<div class="h-100" style="display: flex; flex-direction: column">

  <h1>Berechtigungen</h1>

  <div #tableParentDiv class="h-100">
    <p-table
      [loading]="loading"
      [scrollHeight]="scrollHeight + 'px'"
      [value]="permissions">
      <ng-template pTemplate="header">
        <tr>
          <th
            (click)="onSortOrderChanged('action')">
            <span>
              <span>Aktion</span>
              <span *ngIf="getSortIndexByFieldName('action')">
                {{getSortIndexByFieldName('action')}}
                <fa-icon [icon]="getSortIconByFieldName('action')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('target')">
            <span>
              <span>Ziel</span>
              <span *ngIf="getSortIndexByFieldName('target')">
                {{getSortIndexByFieldName('target')}}
                <fa-icon [icon]="getSortIconByFieldName('target')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('name')">
            <span>
              <span>Name</span>
              <span *ngIf="getSortIndexByFieldName('name')">
                {{getSortIndexByFieldName('name')}}
                <fa-icon [icon]="getSortIconByFieldName('name')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('description')">
            <span>
              <span>Beschreibung</span>
              <span *ngIf="getSortIndexByFieldName('description')">
                {{getSortIndexByFieldName('description')}}
                <fa-icon [icon]="getSortIconByFieldName('description')"></fa-icon>
              </span>
            </span>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template let-permission pTemplate="body">
        <tr *ngIf="!loading">
          <td style="width: 10%;">{{permission.action}}</td>
          <td style="width: 20%;">{{permission.target}}</td>
          <td style="width: 25%;">{{permission.name}}</td>
          <td style="width: 55%;">{{permission.description}}</td>
          <td class="permission-table-column-action-buttons">
            <core-button-icon
              (click)="onEditPermissionClick(permission)"
              [type]="userCanEdit ? InputButtonIconType.EDIT : InputButtonIconType.READ">
            </core-button-icon>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <ng-container *ngFor="let p of ArrayUtils.of(skeletonSize)">
          <tr style="height: 40px">
            <td style="width: 10%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 15%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 22%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 38%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 10%">
              <p-skeleton></p-skeleton>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>

</div>
