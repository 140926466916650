<div class="side-menu-container">

  <!-- Logo -->
  <div class="side-menu-header side-menu-flex">

    <div *ngIf="false" class="side-menu-expand-button">
      <i (click)="onExpandButtonClicked()" class="pi pi-bars"></i>
    </div>

    <img
      *ngIf="menuExpanded"
      [routerLink]="landingPage"
      [src]="'./assets/pictures/logo.png'"
      alt="logo"
      class="side-menu-logo">

  </div>

  <!-- Navigation -->
  <div class="side-menu-body">

    <ng-container *ngFor="let list of Constants.MENU">
      <ng-container *ngIf="isGroupMenuVisible(list)">
        <div *ngIf="menuExpanded" class="side-menu-body-menu-header">
          {{list.name}}
        </div>
        <ng-container *ngFor="let items of list.items">
          <div
            *ngIf="isMenuVisible(items.permissions)"
            [routerLink]="items.link"
            class="side-menu-body-menu-item side-menu-flex"
            routerLinkActive="side-menu-item-active">
            <fa-icon [fixedWidth]="true" [icon]="items.icon" class="side-menu-body-menu-item-icon"></fa-icon>
            <div *ngIf="menuExpanded" class="side-menu-body-menu-item-text">{{items.name}}</div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>

  <!-- Footer -->
  <div [ngClass]="!menuExpanded ? 'side-menu-footer-not-expanded' : ''" class="side-menu-footer">
    <div (click)="op.toggle($event)" class="side-menu-content" id="side-menu-footer">
      <fa-icon [icon]="['fas', 'user-circle']" [size]="'3x'"></fa-icon>
      <div class="side-menu-footer-username">{{ username ? username : 'No user'}}</div>
      <p-overlayPanel #op>
        <div id="button-logout" (click)="onLogout()" style="cursor: pointer">
          <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
          Logout
        </div>
        <div id="button-change-password" (click)="onChangePassword()" style="cursor: pointer">
          <fa-icon [icon]="['fas', 'key']"></fa-icon>
          Passwort ändern
        </div>
        <div id="button-reset-password" (click)="onResetPassword()" style="cursor: pointer">
          <fa-icon [icon]="['fas', 'key']"></fa-icon>
          Passwort zurücksetzten
        </div>
      </p-overlayPanel>
      <div [pTooltip]="getAppInfoTooltip()" class="side-menu-footer-version" tooltipPosition="top">
        <ng-container *ngIf="menuExpanded">Version: {{appInfo?.version ? appInfo.version : 'unknown'}}</ng-container>
        <ng-container *ngIf="!menuExpanded">V{{appInfo?.version ? appInfo.version : 'unknown'}}</ng-container>
      </div>
    </div>
  </div>

</div>
