<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div
  style="position: relative"
  class="core-input-label-container"
  (appDropZone)="onUpload($event)"
  (dropOver)="dropOver($event)">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''"
       class="p-inputgroup core-input-container core_input_flex_between">
    <div class="bord">
      <button
        [style]="{
      'border-color': control.touched && control.errors ? 'inherit' : '',
      'display': 'block',
      'height': '27px',
      'width': '99%',
      'border-bottom-right-radius': 0,
      'border-radius': '5px',
      'margin': '3px'}"
        (click)="inputFileUpload.click()">
        Datei auswählen oder hier ablegen!
      </button>
      <input
        #inputFileUpload
        type="file"
        style="display: none"
        [disabled]="disabled"
        [ngClass]="control.touched && control.errors ? 'core_input_invalid' : ''"
        (input)="onUploadInput($event.target)"
      >
    </div>
    <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
    <button
      (click)="onClear()"
      [disabled]="disabled"
      class="p-button-secondary core-input-button-clear p-inputgroup-addon"
      icon="pi pi-times"
      pButton
      tabindex="-1">
    </button>
  </div>
  <div *ngIf="dropOverActive" class="dropover"></div>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
</div>
