import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {Observable} from 'rxjs';
import {User} from '../../dtos/user/User';
import {UserFilter} from '../../dtos/user/UserFilter';
import {PagedUser} from '../../dtos/user/PagedUser';
import {UserChangePassword} from '../../dtos/user/UserChangePassword';
import {UserCreate} from '../../dtos/user/UserCreate';
import {UserUpdate} from '../../dtos/user/UserUpdate';
import {UserDetail} from '../../dtos/user/UserDetail';
import {Registration} from '../../dtos/authentication/Registration';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {Sort} from '../../components/core/table/sort/Sort';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  pageMinDelay: number = this.globals.pageMinDelay;

  private userBaseUri: string = this.globals.backendUri + '/users';
  private userRegisterUri: string = this.globals.backendUri + '/users/register';

  constructor(private httpClient: HttpClient, private globals: Globals, private authenticationService: AuthenticationService) {
  }

  /**
   * Loads all users from the backend
   */
  getUsers(userFilter: UserFilter): Observable<PagedUser> {
    console.log('getUsers', JSON.stringify(userFilter));
    if (!userFilter) {
      console.log('UserFilter must be defined');
      return new Observable<PagedUser>();
    }
    if (userFilter.page === null || userFilter.page === undefined || userFilter.page < 0) {
      console.log('Invalid page value ' + userFilter.page);
      return new Observable<PagedUser>();
    }
    if (!userFilter.size || userFilter.size < 1) {
      console.log('Invalid page size ' + userFilter.size);
      return new Observable<PagedUser>();
    }

    let params = new HttpParams();
    params = params.set('size', userFilter.size.toString());
    params = params.set('page', userFilter.page.toString());
    params = params.set('sortList', Sort.mapList(userFilter.sortList));
    if (userFilter.email) {
      params = params.set('email', userFilter.email);
    }
    if (userFilter.firstName) {
      params = params.set('firstName', userFilter.firstName);
    }
    if (userFilter.lastName) {
      params = params.set('lastName', userFilter.lastName);
    }
    if (userFilter.locked != null) {
      params = params.set('locked', String(userFilter.locked));
    }
    return this.httpClient.get<PagedUser>(this.userBaseUri, {params});
  }

  /**
   * Loads specific user from the backend
   *
   * @param id of user to load
   */
  getUserById(id: number): Observable<UserDetail> {
    console.log('Load user details for ' + id);
    return this.httpClient.get<UserDetail>(this.userBaseUri + '/' + id);
  }

  /**
   * Loads current user from the backend
   */
  getCurrentUser(): Observable<UserDetail> {
    console.log('Load details for current user');
    return this.httpClient.get<UserDetail>(this.userBaseUri + '/current');
  }

  /**
   * Persists user to the backend
   *
   * @param user to persist
   */
  createUser(user: UserCreate): Observable<User> {
    console.log('Create user with email ' + user.email);
    return this.httpClient.post<User>(this.userBaseUri, user);
  }

  /**
   * Persists user to the backend
   * without any groups and without any permissions
   *
   * @param registration to persist
   */
  registerUser(registration: Registration): Observable<User> {
    console.log('Create user with email ' + registration.email);
    return this.httpClient.post<User>(this.userRegisterUri, registration).pipe(tap((createdUser: any) => {
      console.log('Login successful for user ' + registration.email);
      this.authenticationService.setToken(createdUser.token);
    }));
  }

  /**
   * Updates the specified user.
   *
   * @param user the use to be updated
   */
  update(user: UserUpdate): Observable<User> {
    console.log('Update user with id ' + user.id);
    return this.httpClient.put<User>(this.userBaseUri, user).pipe(tap((updatedUser: any) => {
      if (updatedUser.token && updatedUser.token.length > 0) {
        console.log('Token updated for user ' + updatedUser.email);
        this.authenticationService.setToken(updatedUser.token);
      }
    }));
  }

  /**
   * Resets the password of the specified user, i.e. sending a new password via e-mail.
   *
   * @param email the email to reset the password
   */
  resetUserPassword(email: string) {
    console.log('Reset password for user with id ' + email);
    return this.httpClient.post(this.userBaseUri + '/reset-password', email).pipe(tap((resp: any) => {
      console.log('Password successfully reset for user ' + email);
      if (this.authenticationService.getUserNameFromToken() === email) {
        this.authenticationService.logoutUser();
      }
    }));
  }

  /**
   * Updates the users password
   *
   * @param dto Dto containing the old and new password
   * @param id Id of the user to update the password for
   */
  changeUserPassword(dto: UserChangePassword, id: number) {
    console.log('Update users password for user with id ' + id);
    return this.httpClient.post(this.userBaseUri + '/' + id + '/change-password', dto);
  }

  /**
   * Deletes the user with the specified id.
   *
   * @param id the id of the user to be deleted
   */
  deleteUserById(id: number) {
    console.log('Delete user with id ' + id);
    return this.httpClient.delete(this.userBaseUri + '/' + id);
  }

}
