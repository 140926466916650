<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div
  style="position: relative"
  class="core-input-label-container">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''"
       class="p-inputgroup core-input-container core_input_flex_between">

    <div class="preview-container">
      <h3>
        {{value?.name}}
      </h3>
        <p-image
          [src]="value?.resource"
          [alt]="value?.name"
          [preview]="allowDetailView">
        </p-image>
      <div>
        created: {{value?.created}}
      </div>
      <div>
        createdBy: {{value?.createdBy}}
      </div>
    </div>
  </div>
  <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
</div>

