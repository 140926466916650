export class FileCreate {

  content: any;

  file: {
    name: string,
    description: string
  };

}
