// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
// PrimeNg Exports
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {DragDropModule} from 'primeng/dragdrop';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SidebarModule} from 'primeng/sidebar';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {DividerModule} from 'primeng/divider';
import {RippleModule} from 'primeng/ripple';
import {PasswordModule} from 'primeng/password';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ProgressBarModule} from 'primeng/progressbar';
// custom
import {AuthenticationTokenGuard} from '../guards/authentication-token-guard.service';
import {CoreModule} from '../components/core/core.module';
import {CoreButtonModule} from '../components/core/button/core-button.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SkeletonModule} from 'primeng/skeleton';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    // PrimeNg Exports
    AutoCompleteModule,
    CoreButtonModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DividerModule,
    DragDropModule,
    DropdownModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    KeyFilterModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PasswordModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RippleModule,
    SidebarModule,
    SliderModule,
    SplitButtonModule,
    SkeletonModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TreeTableModule,
    VirtualScrollerModule,
    // FontAwesomeModule
    FontAwesomeModule,
    // Custom
    CoreModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
    AuthenticationTokenGuard
  ]
})
export class SharedModule {
}
