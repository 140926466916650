<div class="core-edit-max-width">

  <h1>Benutzer bearbeiten</h1>

  <form *ngIf="permission && form" [formGroup]="form">

    <core-input-text
      formControlName="action"
      id="action"
      label="Aktion">
    </core-input-text>

    <core-input-text
      formControlName="target"
      id="target"
      label="Ziel">
    </core-input-text>

    <core-input-text
      formControlName="name"
      id="name"
      label="Name">
    </core-input-text>

    <core-input-text
      formControlName="description"
      id="description"
      label="Beschreibung">
    </core-input-text>

    <core-button-submit
      (click)="submitChanges()"
      [disabled]="form.invalid || form.disabled"
      id="button_submit">
    </core-button-submit>

  </form>

</div>
